

.form__field {
    display     : flex;
    align-items : center;

    & + & {
        margin-top: $line-height-m;
    }
}

.form__label {
    // padding-right: $line-height-m*0.25;
    // margin-top : $line-height-m;
    width: 7rem;

    text-align: center;
}

.form--loading {
    pointer-events: none;
    cursor: not-allowed;
    opacity: 0.33;
}


.form__input,
.form__textarea {
    display    : block;
    box-sizing : border-box;

    width         : 100%              ;
    height        : $line-height-m*2  ;
    padding       : 0 $line-height-m  ;
    border        : none              ;
    outline       : 1px solid black ;
    border-radius : 1em               ;

    font-family : inherit ;
    font-size   : inherit ;

    &:focus {
        outline: 1px solid $color-blue;
    }

    &.succeeded { display: none; }
}

.form__textarea {
    height  : $line-height-m*4 ;
    padding : $line-height-m   ;
    
    text-align: left;
    
    resize: none;
}


.form__send-button {  // The SEND button
    width         : 100%             ;
    height        : $line-height-m*2 ;
    margin-top    : $line-height-m   ;
    border        : none             ;
    border-radius : 1em              ;

    font-family : inherit;
    font-size   : 1rem;

    background-color : $color-blue  ;
    color            : $color-white ;

    cursor : pointer ;
}

.error-message {
    color      : red;
    text-align : center;
}

.success-message { text-align : center; }