* {
    margin     : 0;
    padding    : 0;
    box-sizing : border-box;
}


html { height    : 100%; }


body { height: 100%; }

$color-blue  : #3364C3;
$color-black : #272727;
$color-grey  : #4D4D4D;
$color-white : #FFFFFF;
$color-live  : #B60000;

$line-height-m: 1.5rem;