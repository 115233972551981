.cta {
    display        : flex;
    flex-direction : column;
    align-items    : center;

    position  : absolute;
    top       : 50%;
    left      : 50%;
    // Compensating for half of expected header height while pulling cta up
    transform : translate(-50%, calc(-50% - $line-height-m) );
    
    width   : 100%;
    
    text-align: center;

    & > * + * { margin-top: $line-height-m; }
}


a.cta__button {
    padding       : $line-height-m*0.5 $line-height-m*2;
    border-radius : 1em;
    
    font-family      : inherit;
    text-decoration  : none;
    color            : $color-white;
    background-color : $color-blue;

    cursor: pointer;
}


.learn-more {
    position : absolute;
    bottom   : $line-height-m;
    right    : $line-height-m;
}