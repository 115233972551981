
.project {
    text-align: center;
    outline: 1px solid black;

    border-radius: 0.5em;
    // border: 1px solid black;
    overflow: hidden;

    & + & {
        margin-top: $line-height-m*2;
    }
}


.project__name {
    padding: $line-height-m*0.5;
    box-shadow: inset 0px -1px black;
    color: $color-black;
    // color: $color-white;
    // background-color: $color-grey;
}


.project__image-container {
    position : relative;
    
    width          : 100%;
    padding-bottom : 62.5%;
}


.project__image {
    display : block;
    
    position : absolute;
    
    width      : 100%;
    max-height : 100%;
    
    margin     : 0;
    object-fit : cover;
}


.project__link,
.project__details-container {
    display : block;

    padding    : $line-height-m*0.5 $line-height-m ;

    color            : $color-blue;
    text-decoration  : none;
    background-color : $color-white;
    box-shadow       : inset 0px 1px $color-black;
}


.project__link.project__link-live {
    color            : $color-white;
    background-color : $color-live;
}


.project__details-container {
    position : relative;
    cursor   : pointer;
    // cursor   : pointer;
    color    : $color-blue;
    // background-color : $color-grey;
}


.project__details-arrow {
    display : inline;

    position : absolute;
    top      : $line-height-m;
    right    : 1em;
    // right    : $line-height-m;
    
    height    : $line-height-m;
    width     : $line-height-m;
    transform : translateY(-50%);

    fill         : none;
    stroke       : $color-blue;
    stroke-width : 2px;
}
.accordion--expanded .project__details-arrow {
    // stroke: darkorange;
    transform : rotate(180deg)
                translateY(50%);
}

.project__details-text {
    color: $color-black;
    text-align: left;
}

// Reimplement later - and better
// .project__details {
//     display               : grid;
//     grid-template-columns : $line-height-m*4 1fr;
//     grid-auto-rows        : auto;
//     gap                   : $line-height-m*0.5;

//     margin-top : $line-height-m*0.5;
    
//     color      : black;
//     text-align : left;

//     p { width: 100%; }
// }


.accordion {
    max-height : $line-height-m*2;
    overflow   : hidden;
}


.accordion--expanded { max-height: none; }