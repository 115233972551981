.header {
    display         : flex;
    justify-content : center;
    
    background-color : $color-grey;
}


.header__header-links {
    display : flex;
    justify-content: center;
    
    width: 100%;
}


.header__header-link {
        display         : flex   ;
        align-items     : center ;
        justify-content : center ;

        position : relative;
        
        flex   : 1                ;
        height : $line-height-m*2 ;

        color           : $color-white ;
        text-decoration : none         ;
}


.header__link-icon {
    position  : absolute;
    top       : 50%;
    left      : 50%;
    transform : translate(-50%, -50%);
    
    width  : $line-height-m;
    height : $line-height-m;
    fill   : none;
    stroke : $color-white;
    
    &.icon-home     { stroke-width: 10px  ; }
    &.icon-about    { stroke-width: 18px  ; }
    &.icon-contact  {
        width        : $line-height-m*1.2;
        height       : $line-height-m*1.2;
        stroke-width: 0.7px;
    }
    &.icon-projects {
        width        : $line-height-m*1.2;
        height       : $line-height-m*1.2;
        stroke-width : 1.1px ;
    }
}


.header__header-link.active {
    // color            : $color-white;
    background-color : $color-blue;

    // & .header__link-icon { stroke: $color-white; }
}


.header__link-text { display: none; }