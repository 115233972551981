@import url('https://fonts.googleapis.com/css2?family=Nunito:wght@400;500;600;700&display=swap');


html {
    font-family: 'Nunito', sans-serif;
    line-height: 1.5rem;
}

h3 {
    font-size   : 1.2rem;
    font-weight : 500;
    line-height : $line-height-m;
}

h2 {
    font-size   : 1.44rem;
    font-weight : 600;
    line-height : $line-height-m;

    &.page-name {
        text-align    : center;
        margin-bottom : $line-height-m*2;
    }
}

h1 {
    font-size   : 2.488rem;
    font-weight : 700;
    line-height : $line-height-m * 2;
}

p + p { margin-top: $line-height-m; }

a {color: $color-blue;}